<template>
  <p class="text-subtitle-1 font-weight-medium mb-1">
    {{ title }}
  </p>
</template>

<script>
export default {
  props: ['title']
  // props: {
  //   title: {
  //     type: String,
  //     default: 'Title'
  //   }
  // }
}
</script>

<style lang="scss" scoped>

</style>
